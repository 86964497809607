import { createAction } from '@reduxjs/toolkit';

export interface LoginPayload {
  token: string;
  id: string;
  short_id?: string;
  url_tag?: string;
}

export const loginAction = createAction<LoginPayload>('loginAction');
export const logoutAction = createAction('logoutAction');
export const loadDocsAction = createAction<any>('loadDocsAction');
