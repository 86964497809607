import { useState } from 'react';
import ReactGA from 'react-ga4';
import Modal from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { axiosPost } from '../../hooks/useAxios';
import Evil from '../../components/Evil/Evil';
import { useIsMyself } from '../../redux/selectors';

import modalStyles from '../../components/Modal/Modal.module.scss';
import styles from './ContactForm.module.scss';
import { FormInput } from '../../components/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import { emailRegex } from '../../helpers';

interface ContactFormProps {
  targetUser: string;
  therapistName: string;
  buttonChildren: any;
  buttonClassName?: string;
}

function ContactForm(props: ContactFormProps) {
  const { targetUser, therapistName, buttonChildren, buttonClassName } = props;

  const [isRobot, setIsRobot] = useState(false);
  const [success, setSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isMyself = useIsMyself(targetUser);
  const form = useForm();

  const onSubmit = async (formData: any) => {
    if (isRobot) {
      setErrorMessage('Please check the box to confirm you are human.');
      return;
    }

    setErrorMessage('');
    setIsLoading(true);

    const result = await axiosPost('/api/contact', {
      ...formData,
      recipient_user: targetUser,
      is_phone: false,
    });

    setIsLoading(false);

    if (result) {
      if (!isMyself) {
        ReactGA.event({ category: 'Conversion', action: 'Therapist Contact' });
      }

      setSuccess(true);
    } else {
      setErrorMessage(
        'Something went wrong. Please check your email or try again later.',
      );
    }
  };

  const createOnFinish = (closeModal: any) => () => {
    setSuccess(false);
    closeModal();
  };

  const createSuccessMessage = (closeModal: any) => (
    <div className={modalStyles.form}>
      <div className={modalStyles.header}>Message Sent</div>
      <div>
        Your message has sent succesfully. Please keep an eye out for a reply
        from the therapist soon. We wish you all the best.
      </div>

      <div className="faded">
        Your friend, <br />
        Team MintLeaf
      </div>

      <div className="form-actions">
        <Button className="btn" onClick={createOnFinish(closeModal)}>
          Got it
        </Button>
      </div>
    </div>
  );

  const createForm = (closeModal: any) => (
    <div className={modalStyles.form}>
      <div>
        <div className={modalStyles.header}>Email {therapistName}</div>

        <div className={styles.explanation}>
          Answer some questions below and {therapistName} will be in touch to
          schedule a brief consultation about your needs.
        </div>
      </div>

      <div className="form-field">
        <span className="form-label">Name</span>
        <FormInput
          form={form}
          name="name"
          autoComplete="name"
          placeholder="Your name"
          maxLength={100}
          required
          data-private
        />
      </div>

      <div className="form-field">
        <span className="form-label">What can I help you with?</span>
        <div className={styles.contactMessage} data-private>
          <FormInput
            form={form}
            name="private_message"
            type="textarea"
            placeholder="What brings you to therapy?"
            required
            maxLength={2000}
          />
        </div>
      </div>

      <div className="form-field">
        <span className="form-label">How can {therapistName} reach you?</span>
        <div className="form-field-split">
          <FormInput
            form={form}
            name="author_email"
            type="email"
            autoComplete="email"
            placeholder="Your email"
            maxLength={254}
            pattern={{
              value: emailRegex,
              message: 'Please enter a valid email.',
            }}
            required
            data-private
          />

          <FormInput
            form={form}
            name="phone"
            type="tel"
            autoComplete="tel"
            placeholder="Your phone (optional)"
            maxLength={25}
            data-private
          />
        </div>
      </div>

      <Evil setIsRobot={setIsRobot} />

      {errorMessage && <div className="form-error">{errorMessage}</div>}

      <div className="form-actions">
        <Button
          className="btn"
          color="grey"
          onClick={closeModal}
          disabled={isLoading}
        >
          Cancel
        </Button>

        <Button
          className="btn"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send Email'}
        </Button>
      </div>

      <div className={styles.note}>
        MintLeaf does NOT retain a copy of your message or read them, although
        we will forward you a copy for your records. Emails are not a secure
        means of communication and personal health information should not be
        sent. Spam filters may prevent the therapist from receiving your email.
        If you are dealing with an emergency call 911.
      </div>
    </div>
  );

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => {
        return success
          ? createSuccessMessage(closeModal)
          : createForm(closeModal);
      }}
    />
  );
}

export default ContactForm;
