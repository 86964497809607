import styles from './ContactCTA.module.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import ContactForm from '../ContactForm/ContactForm';
import { useDocSelector, useIsMyself } from '../../redux/selectors';
import { formatPhone } from '../../helpers';
import { axiosPost } from '../../hooks/useAxios';
import ReactGA from 'react-ga4';

interface ContactCTAProps {
  targetUser: string;
}

function ContactCTA(props: ContactCTAProps) {
  const { targetUser } = props;
  const userDoc = useDocSelector({ collection: 'user', id: targetUser });
  const isMyself = useIsMyself(targetUser);

  if (!userDoc) {
    return <Skeleton card count={6} />;
  }

  const { phone, first_name } = userDoc;

  const trackContact = () => {
    if (!isMyself) {
      axiosPost('/api/contact', { recipient_user: targetUser, is_phone: true });

      ReactGA.event({
        category: 'Conversion',
        action: 'Therapist Contact Phone',
      });
    }
  };

  return (
    <>
      {phone && (
        <a
          href={`tel:${phone}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.phoneBtn}
          onClick={trackContact}
        >
          {formatPhone(phone)}
        </a>
      )}

      <ContactForm
        targetUser={targetUser}
        therapistName={first_name}
        buttonChildren={<>Email Me</>}
        buttonClassName={styles.emailBtn}
      />
    </>
  );
}

export default ContactCTA;
