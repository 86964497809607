import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import isbot from 'isbot';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
// import LogRocket from 'logrocket';
// import ReactPixel from 'react-facebook-pixel';

const { hostname } = window.location;
const userAgent = window?.navigator?.userAgent;

export const isBot = isbot(userAgent);
export const isProduction = hostname === 'mintleaf.co';

const shouldTrack = isProduction && !isBot;

if (shouldTrack) {
  ReactGA.initialize('G-ZHTETNZ3X1');

  // ReactPixel.init('325225235323945', undefined, {
  //   autoConfig: true,
  //   debug: false,
  // });

  // LogRocket.init('bvufjf/mintleaf');

  Sentry.init({
    dsn: 'https://af254f58bec842a386eab4deef1cd3a6@o321985.ingest.sentry.io/5200282',
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
  });
}

export default function Tracker() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (shouldTrack) {
      const userId = localStorage.getItem('id');
      const url_tag = localStorage.getItem('url_tag');

      ReactGA.set({ page: pathname, userId });

      if (userId && url_tag) {
        Sentry.setUser({ id: userId, username: url_tag });
      }
    }
  }, [pathname]);

  return null;
}
