import Modal from '../../components/Modal/Modal';
import { useCurrentUserSelector } from '../../redux/selectors';
import _ from 'lodash';
import { Option } from '../../helpers';
import { getProfileLink, valuesToOptions } from '../../helpers';
import { useEffect, useRef, useState } from 'react';
import modalStyles from '../../components/Modal/Modal.module.scss';
import styles from './ShareProfile.module.scss';
import Select from '../../components/Select/Select';
import { Button } from '../../components/Button/Button';
import {
  IoIosCheckmarkCircleOutline,
  IoIosCopy,
  IoIosInformationCircle,
} from 'react-icons/io';
import Tooltip from '../../components/Tooltip/Tooltip';

interface ShareProfileProps {
  buttonChildren: any;
}

function ShareProfile(props: ShareProfileProps) {
  const { buttonChildren } = props;
  const userDoc = useCurrentUserSelector();

  const anchorRef = useRef<HTMLAnchorElement>(null);

  const [size, setSize] = useState<Option>();
  const [theme, setTheme] = useState(valuesToOptions('Light') as Option);
  const sizeOptions = valuesToOptions(['Small', 'Large']) as Option[];
  const themeOptions = valuesToOptions(['Light', 'Dark']) as Option[];

  const [logoStyles, setLogoStyles] = useState({});
  const [linkStyles, setLinkStyles] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    let linkStyles: any = {
      textDecoration: 'none',
      borderRadius: '8px',
      fontFamily: 'Arial, sans-serif',
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '15px',
      boxShadow: '0 1px 7px rgba(0, 20, 0, 0.07)',
      padding: '8px 10px',
      paddingLeft: '10px',
      backgroundColor: '#ffffff',
      color: 'hsl(208, 10%, 30%)',
      border: '2px solid hsl(209, 41%, 90%)',
      fontWeight: '600',
    };

    let logoStyles: any = {
      fontFamily: 'Georgia, serif',
      backgroundColor: 'hsl(160, 25%, 50%)',
      padding: '3px 6px',
      fontSize: '12px',
      color: '#ffffff',
      borderRadius: '3px',
    };

    if (theme?.value === 'Dark') {
      linkStyles = {
        ...linkStyles,
        backgroundColor: 'hsl(210, 10%, 20%)',
        color: '#ffffff',
        border: '2px solid hsl(210, 10%, 40%)',
      };
    }

    if (size?.value === 'Large') {
      linkStyles = {
        ...linkStyles,
        fontSize: '17px',
        padding: '12px 14px',
      };

      logoStyles = {
        ...logoStyles,
        fontSize: '16px',
      };
    }

    setLogoStyles(logoStyles);
    setLinkStyles(linkStyles);
  }, [size, theme]);

  useEffect(() => {
    if (anchorRef.current) {
      setCode(`<!-- MintLeaf Share Link -->
        ${replaceRgbWithHex(anchorRef.current.outerHTML)}
        <script type="text/javascript" src="https://mintleaf.co/share-link.js" data-id="${
          userDoc.short_id
        }" data-size="${size?.value}" data-theme="${theme?.value}"></script>
        <!-- End Link -->`);
    }
  }, [logoStyles, linkStyles]);

  if (!userDoc) {
    return null;
  }

  const onClickCopy = () => {
    setIsSuccess(true);
    navigator.clipboard.writeText(code);
    setTimeout(() => setIsSuccess(false), 1500);
  };

  return (
    <Modal
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={modalStyles.form}>
          <div>
            <div className={modalStyles.header}>Share Profile</div>

            <div className={modalStyles.subheader}>
              Get a shareable link to your MintLeaf profile. Adding this link to
              your professional website gives potential clients another way to
              get a better feel for your practice.
            </div>
          </div>

          <div className="form-field">
            <div className="form-field-split">
              <div>
                <span className="form-label">Select a size</span>

                <Select value={size} onChange={setSize} options={sizeOptions} />
              </div>

              <div>
                <span className="form-label">Select a theme</span>

                <Select
                  value={theme}
                  onChange={setTheme}
                  options={themeOptions}
                />
              </div>
            </div>
          </div>

          {theme && size && (
            <>
              <div className="form-field">
                <span className="form-label">Preview your link</span>

                <div className={styles.preview}>
                  <a
                    id={`mintleaf-${userDoc.short_id}`}
                    ref={anchorRef}
                    href={`https://mintleaf.co${getProfileLink(userDoc)}`}
                    style={linkStyles}
                  >
                    <span style={logoStyles}>M</span>
                    <span style={{ margin: '0 8px', color: '#9fb3c6' }}>|</span>
                    <span>MintLeaf Therapy</span>
                  </a>
                </div>
              </div>

              <div className="form-field">
                <span className="form-label">
                  <Tooltip content="This code can be pasted directly into your website's HTML editor. This will often be the About section or the footer of the website.">
                    <span className={styles.codeTip}>
                      Your link code
                      <IoIosInformationCircle />
                    </span>
                  </Tooltip>
                </span>

                <div className={styles.badgeCode}>
                  {code}

                  <div className={styles.copyCode}>
                    <Button
                      className="btn"
                      color={isSuccess ? 'green' : 'primary'}
                      onClick={onClickCopy}
                    >
                      {isSuccess && (
                        <>
                          <IoIosCheckmarkCircleOutline />
                          <span>Copied!</span>
                        </>
                      )}

                      {!isSuccess && (
                        <>
                          <IoIosCopy />
                          <span>Copy Code</span>
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="form-actions">
            <Button className="btn" onClick={closeModal}>
              Return to Profile
            </Button>
          </div>
        </div>
      )}
    />
  );
}

function rgbToHex(r = '', g = '', b = '', a = null) {
  const red = parseInt(r, 10).toString(16).padStart(2, '0');
  const green = parseInt(g, 10).toString(16).padStart(2, '0');
  const blue = parseInt(b, 10).toString(16).padStart(2, '0');

  if (a) {
    const alpha = Math.round(parseFloat(a) * 255)
      .toString(16)
      .padStart(2, '0');
    return `#${red}${green}${blue}${alpha}`;
  }

  return `#${red}${green}${blue}`;
}

function replaceRgbWithHex(input: string) {
  return input.replace(
    /rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*([0-1](?:\.\d+)?))?\)/g,
    (_, r, g, b, a) => rgbToHex(r, g, b, a),
  );
}

export default ShareProfile;
