import { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import landingStyles from './layouts/LandingClient/LandingClient.module.scss';
import stateStyles from './layouts/State/State.module.scss';
import profileStyles from './layouts/Profile/Profile.module.scss';

import Navbar from './layouts/Navbar/Navbar';

import { getStateUrl, openStates, quizzes } from './helpers';
import _ from 'lodash';
import { HeaderTags } from './components/HeaderTags/HeaderTags';

const LoadTags = lazy(() => import('./containers/LoadTags/LoadTags'));
const LoadProfileUser = lazy(
  () => import('./containers/LoadProfileUser/LoadProfileUser'),
);
const LandingTherapist = lazy(
  () => import('./layouts/LandingTherapist/LandingTherapist'),
);
const LandingClient = lazy(
  () => import('./layouts/LandingClient/LandingClient'),
);
const State = lazy(() => import('./layouts/State/State'));

const PageScrollToTop = lazy(() => import('./hooks/ScrollToTop'));
const Tracker = lazy(() => import('./hooks/Tracker'));
const NotFound = lazy(() => import('./layouts/NotFound/NotFound'));
const Footer = lazy(() => import('./components/Footer/Footer'));

// const Billing = lazy(() => import('./containers/Billing/Billing'));
const Login = lazy(() => import('./layouts/Login/Login'));
const SignUpTherapist = lazy(
  () => import('./layouts/SignUpTherapist/SignUpTherapist'),
);
const ResetPassword = lazy(
  () => import('./layouts/ResetPassword/ResetPassword'),
);
const VerifyEmail = lazy(() => import('./layouts/VerifyEmail/VerifyEmail'));
const LegalPrivacy = lazy(() => import('./layouts/LegalPrivacy/LegalPrivacy'));
const LegalTerms = lazy(() => import('./layouts/LegalTerms/LegalTerms'));
const LegalGuidelines = lazy(
  () => import('./layouts/LegalGuidelines/LegalGuidelines'),
);
const SearchPost = lazy(() => import('./layouts/SearchPost/SearchPost'));
const Quiz = lazy(() => import('./components/Quiz/Quiz'));

function routeStates() {
  return _.map(openStates, (state) => (
    <Route
      path={`${getStateUrl(state)}/:tag?`}
      key={state}
      element={
        <Suspense fallback={<div className={stateStyles.searchPage} />}>
          <State state={state} />
          <LoadTags />
        </Suspense>
      }
    />
  ));
}

function routeQuizzes() {
  return _.map(quizzes, (quiz) => (
    <Route
      path={quiz.url}
      key={quiz.url}
      element={
        <Suspense fallback={<div className={stateStyles.searchPage} />}>
          <Quiz quiz={quiz} />
        </Suspense>
      }
    />
  ));
}

function App() {
  return (
    <Router>
      <Navbar />

      <HeaderTags
        title="MintLeaf | Find a Therapist, Psychologist, Counselor"
        description="Find the right therapist for you. See how each therapist talks about their approach to mental health."
        url="https://mintleaf.co"
      />

      <div className="page-container">
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<div className={landingStyles.landingTop} />}>
                <LandingClient />
              </Suspense>
            }
          />

          <Route
            path="/therapist/:short_id"
            element={
              <Suspense
                fallback={<div className={profileStyles.profilePage} />}
              >
                <LoadProfileUser />
                <LoadTags />
              </Suspense>
            }
          />

          <Route
            path="/therapist/:url_tag/:short_id"
            element={
              <Suspense
                fallback={<div className={profileStyles.profilePage} />}
              >
                <LoadProfileUser />
                <LoadTags />
              </Suspense>
            }
          />

          {routeStates()}

          <Route
            path="/therapists"
            element={
              <Suspense fallback={<div className={landingStyles.landingTop} />}>
                <LandingClient />
              </Suspense>
            }
          />

          <Route
            path="/for-providers"
            element={
              <Suspense fallback={<div className={landingStyles.landingTop} />}>
                <LandingTherapist />
              </Suspense>
            }
          />

          <Route
            path="/register"
            element={
              <Suspense fallback={null}>
                <SignUpTherapist />
              </Suspense>
            }
          />

          <Route
            path="/login"
            element={
              <Suspense fallback={null}>
                <Login />
              </Suspense>
            }
          />

          <Route
            path="/posts/:slug"
            element={
              <Suspense fallback={<div className={stateStyles.searchPage} />}>
                <SearchPost />
                <LoadTags />
              </Suspense>
            }
          />

          {/* {routeQuizzes()} */}

          <Route
            path="/reset"
            element={
              <Suspense fallback={null}>
                <ResetPassword />
              </Suspense>
            }
          />

          <Route
            path="/verify"
            element={
              <Suspense fallback={null}>
                <VerifyEmail />
              </Suspense>
            }
          />

          {/* <Route path="/billing">
              <Billing />
            </Route> */}

          <Route
            path="/legal/privacy-policy"
            element={
              <Suspense fallback={null}>
                <LegalPrivacy />
              </Suspense>
            }
          />

          <Route
            path="/legal/terms-of-use"
            element={
              <Suspense fallback={null}>
                <LegalTerms />
              </Suspense>
            }
          />

          <Route
            path="/legal/user-guidelines"
            element={
              <Suspense fallback={null}>
                <LegalGuidelines />
              </Suspense>
            }
          />

          <Route
            path="/not-found"
            element={
              <Suspense fallback={null}>
                <NotFound />
              </Suspense>
            }
          />

          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </div>

      <Suspense fallback={null}>
        <Tracker />
        <PageScrollToTop />
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
