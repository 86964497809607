import _ from 'lodash';
import { format as formatFn } from 'date-fns';
import { TagDoc, UserDoc } from '../src-server/models';
import axios, { axiosGet } from './hooks/useAxios';
import { useLocation } from 'react-router-dom';
import { useLoginSelector } from './redux/selectors';
import Tooltip from './components/Tooltip/Tooltip';
import * as Sentry from '@sentry/react';
import {
  useSearchableApproaches,
  useSearchableIssues,
} from './layouts/State/State';

export function useAbsoluteUrl() {
  const { pathname } = useLocation();
  return `https://mintleaf.co${pathname}`;
}

export function formatDate(date: Date, format = 'MMM d, yyyy') {
  return formatFn(new Date(date), format);
}

export function formatPhone(phone: string) {
  const numbersOnly = phone.replace(/\D/g, '');
  let localNumber = numbersOnly;

  if (_.size(numbersOnly) === 11 && _.first(numbersOnly) === '1') {
    localNumber = numbersOnly.substring(1);
  }

  if (_.size(localNumber) === 10) {
    const sliceOne = _.slice(localNumber, 0, 3).join('');
    const sliceTwo = _.slice(localNumber, 3, 6).join('');
    const sliceThree = _.slice(localNumber, 6).join('');

    return `(${sliceOne}) ${sliceTwo}-${sliceThree}`;
  }

  return phone;
}

export function getProfileLink(userDoc: Partial<UserDoc>) {
  const { url_tag, short_id } = userDoc;
  const url = url_tag
    ? `/therapist/${url_tag}/${short_id}`
    : `/therapist/${short_id}`;

  return url;
}

export function useDefaultLink() {
  const login = useLoginSelector();
  const short_id = login?.short_id || '';
  const url_tag = login?.url_tag || '';

  return short_id ? getProfileLink({ url_tag, short_id }) : '/';
}

export function getDisplayName(userDoc: UserDoc) {
  const { first_name, last_name } = userDoc;

  return first_name ? `${first_name} ${last_name}` : 'N/A';
}

export function hasTeletherapy(userDoc: UserDoc) {
  const { teletherapy, online_therapy } = userDoc;

  return online_therapy
    ? online_therapy === 'Both' || online_therapy === 'Teletherapy Only'
    : teletherapy;
}

export function hasInPerson(userDoc: UserDoc) {
  const { city, zip, online_therapy } = userDoc;

  return online_therapy
    ? online_therapy === 'Both' || online_therapy === 'In-Person Only'
    : city || zip;
}

export function getDisplayLocation(userDoc: UserDoc) {
  const { city, state, licensed_states } = userDoc;
  const locations = _.uniq(
    _.compact([city, state, ...(licensed_states || [])]),
  );

  let display;

  if (_.size(locations) > 3) {
    display = (
      <Tooltip content={_.slice(locations, 3).join(', ')}>
        <>
          {_.take(locations, 3).join(', ') +
            `, and ${_.size(locations) - 3} more...`}
        </>
      </Tooltip>
    );
  } else {
    display = locations.join(', ');
  }

  return display;
}

export function getShortLocation(userDoc: UserDoc) {
  const { city, state, licensed_states } = userDoc;
  let locations;

  if (state) {
    locations = _.compact([city, state]);
  } else {
    locations = _.compact([_.first(licensed_states)]);
  }

  return locations.join(', ');
}
export function getShortHeadline(userDoc: UserDoc) {
  const { headline } = userDoc;

  return _.truncate(headline, { length: 50 });
}

export function cleanString(rawString?: string) {
  return _.toLower(_.trim(rawString));
}

export function getSlug(string = '') {
  return _.kebabCase(_.trim(shortenDC(string)));
}

export function getStateUrl(state: string, tagLabel?: string) {
  let stateUrl = `/therapists/${getSlug(state)}`;

  if (tagLabel) {
    stateUrl += `/${getSlug(tagLabel)}`;
  }

  return stateUrl;
}

export function getValueFromSlug(values: string[], slug?: string) {
  return _.find(values, (value) => slug === getSlug(value)) || '';
}

export function getTagFromSlug(tagDocs: TagDoc[], slug?: string) {
  return _.find(tagDocs, (tagDoc) => slug === tagDoc.slug) || null;
}

export async function uploadToS3(
  presignEndpoint: string,
  presignParams: any,
  data: any[],
) {
  if (data) {
    const presignedUrls = await axiosGet(presignEndpoint, presignParams);

    const uploads = _.map(
      presignedUrls,
      async ({ url, fields }, index: number) => {
        const formData = new FormData();

        _.each(fields, (value, key) => {
          formData.set(key, value);
        });

        formData.set('file', data?.[index]);

        return axios(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        }).catch((error) => {
          Sentry.captureException(error, { extra: { url } });
        });
      },
    );

    return Promise.all(uploads);
  }
}

export interface Option {
  label: string;
  value: string;
}

export function shortenDC(label: string) {
  return label === 'District of Columbia' ? 'DC' : label;
}

export const valuesToOptions = (input: string[] | string | null) => {
  if (!input) {
    return;
  }

  if (_.isArray(input)) {
    return _.map(input, (inputElement) => {
      return { value: shortenDC(inputElement), label: inputElement };
    });
  }

  return { value: shortenDC(input), label: input };
};

export const tagsToOptions = (input: TagDoc[] | TagDoc | null) => {
  if (!input) {
    return;
  }

  return _.isArray(input)
    ? _.map(input, ({ id, label }) => ({ value: id, label: label }))
    : { value: input.id, label: input.label };
};

export const allGenders = ['Female', 'Male', 'Transgender', 'Non-Binary'];

// 80+ therapists - 7 states
export const insuranceStates = [
  'California',
  'New York',
  'Florida',
  'Texas',
  'Colorado',
  'Illinois',
  'New Jersey',
];

// 7+ therapists - 48 states
export const openStates = _.sortBy([
  'California',
  'Texas',
  'Florida',
  'New York',
  'New Jersey',
  'North Carolina',
  'Colorado',
  'Georgia',
  'Pennsylvania',
  'Illinois',
  'Maryland',
  'Ohio',
  'Arizona',
  'Virginia',
  'Washington',
  'Massachusetts',
  'Utah',
  'Michigan',
  'Tennessee',
  'Missouri',
  'Oklahoma',
  'Alabama',
  'Kentucky',
  'Indiana',
  'Nevada',
  'Minnesota',
  'District of Columbia',
  'Delaware',
  'Louisiana',
  'Connecticut',
  'Idaho',
  'South Carolina',
  'Kansas',
  'Wisconsin',
  'Vermont',
  'Nebraska',
  'Oregon',
  'Rhode Island',
  'New Hampshire',
  'Arkansas',
  'West Virginia',
  'Maine',
  'Mississippi',
  'Wyoming',
  'Hawaii',
  'New Mexico',
  'Iowa',
  'Montana',
]);

export const allStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const statesToAbbrev: any = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const allAgeFocuses = [
  'Toddler',
  'Children (6-10)',
  'Preteen',
  'Teen',
  'Adult',
  'Elders (65+)',
];

export const allEthnicityFocuses = [
  'Asian',
  'Black or African American',
  'Hispanic or Latin',
  'Indian',
  'Middle Eastern',
  'Native American',
  'Pacific Islander',
];

export const allSexualityFocuses = [
  'LGBTQ+',
  'Lesbian',
  'Gay',
  'Bisexual',
  'Transgender',
  'Non-binary',
  'Asexual',
];

const nationalInsurances = [
  'Aetna',
  'Anthem',
  'Blue Cross Blue Shield (BCBS)',
  'Cigna',
  'Humana',
  'Medicaid',
  'Medicare',
  'Molina',
  'Optum',
  'Oscar',
  'Tricare',
  'UnitedHealthcare (UHC / UBH)',
];

const stateToInsurances: any = {
  Alabama: ['Great-West Life'],
  Alaska: ['Moda Health', 'Premera', 'Great-West Life'],
  Arkansas: ['QualChoice'],
  California: ['Kaiser (Out-of-Network)'],
  Colorado: ['Kaiser (Out-of-Network)'],
  Connecticut: [
    'Connecticare',
    'Harvard Pilgrim',
    'Tufts Health Plan',
    'Emblem Health',
    'Oxford Health Plans',
  ],
  DC: ['CareFirst'],
  'District of Columbia': ['CareFirst'],
  Delaware: ['Great-West Life', 'Highmark'],
  Florida: ['Florida Blue', 'Magellan Health'],
  Georgia: ['Kaiser (Out-of-Network)'],
  Hawaii: [
    'Hawaii Medical Assurance Association',
    'Hawaii Medical Services Association',
    'University Health Alliance',
    'Kaiser (Out-of-Network)',
  ],
  Idaho: ['Mountain Health CO-OP', 'PacificSource', 'Regence', 'SelectHealth'],
  Illinois: ['Health Alliance'],
  Indiana: ['CareSource', 'Indiana University Health Plans'],
  Iowa: ['CareSource', 'Indiana University Health Plans', 'Medica', 'Wellmark'],
  Louisiana: ['Vantage Health Plan', 'Magellan Health'],
  Maine: [
    'Maine Community Health Options',
    'Beacon Health Options',
    'Harvard Pilgrim',
    'Tufts Health Plan',
  ],
  Maryland: ['CareFirst', 'Kaiser (Out-of-Network)'],
  Massachusetts: [
    'AllWays Health Partners (MGBHP)',
    'Health New England',
    'Harvard Pilgrim',
    'Tufts Health Plan',
  ],
  Michigan: [
    'Health Alliance Plan of Michigan (HAP)',
    'Physicians Health Plan',
    'Priority Health',
  ],
  Minnesota: ['HealthPartners', 'Medica', 'PreferredOne', 'UCare'],
  Montana: ['PacificSource', 'Montana Health CO-OP'],
  Nevada: ['Hometown Health', 'Prominence Health Plan'],
  'New Hampshire': ['Harvard Pilgrim', 'Tufts Health Plan'],
  'New Jersey': ['AmeriHealth', 'Horizon', 'Oxford Health Plans'],
  'New Mexico': [
    'New Mexico Health Connections',
    'Presbyterian',
    'Magellan Health',
  ],
  'New York': [
    'Excellus BlueCross BlueShield',
    'Emblem Health',
    'Fidelis',
    'Highmark',
    'Oxford Health Plans',
  ],
  'North Dakota': ['Medica', 'Sanford Health Plan', 'Great-West Life'],
  Ohio: ['Medical Mutual'],
  Oregon: [
    'Moda Health',
    'PacificSource',
    'Providence',
    'Regence',
    'Kaiser (Out-of-Network)',
  ],
  Pennsylvania: [
    'Highmark',
    'Magellan Health',
    'Capital Blue Cross',
    'Independence Blue Cross',
    'University of Pittsburgh Medical Center',
  ],
  'Rhode Island': [
    'Brown University Student Health Insurance',
    'Neighborhood Health Plan of Rhode Island',
    'Tufts Health Plan',
  ],
  'South Carolina': ['Great-West Life'],
  'South Dakota': ['Medica', 'Sanford Health Plan', 'Wellmark'],
  Utah: ['Regence', 'SelectHealth'],
  Vermont: ['MVP Health Care', 'Great-West Life'],
  Virginia: ['CareFirst', 'Magellan Health', 'Kaiser (Out-of-Network)'],
  Washington: [
    'First Choice Health',
    'Premera',
    'Regence',
    'Kaiser (Out-of-Network)',
  ],
  'West Virginia': ['CareSource', 'The Health Plan', 'Highmark'],
  Wisconsin: ['Dean Health', 'Quartz'],
  Wyoming: ['Magellan Health'],
};

function getPayments(insurances: string[]) {
  const allInsurances = _.sortBy(
    _.uniq(_.compact(_.flatten(_.concat(nationalInsurances, insurances)))),
  );

  return ['Out-of-Pocket', 'Sliding Scale', ...allInsurances];
}

export function getStatePayments(state: string) {
  return getPayments(stateToInsurances[state]);
}

export function getUserPayments(userDoc: UserDoc) {
  const stateInsurances = _.flatten(
    _.map(userDoc.licensed_states, (state) => stateToInsurances[state]),
  );

  return getPayments(stateInsurances);
}

export const quizzes = [
  {
    title: 'Self Esteem',
    subTitle: '',
    directions: ``,
    url: '/tests/self-esteem',
    questions: [
      'I feel overwhelmed by my emotions.',
      'I am able to handle the level of stress I experience.',
      'I have physical symptoms of anxiety, such as sweaty palms.',
      'I cannot get beyond long-past traumatic events or significant losses.',
    ],
    allOptions: [
      { value: 1, label: 'Not at all' },
      { value: 2, label: 'Several days' },
      { value: 3, label: 'More than half the days' },
      { value: 4, label: 'Nearly every day' },
    ],
  },
  {
    title: 'Depression',
    subTitle: `Many people feel low at some point, but for those with clinical
    depression, such feelings don’t pass; they lead to symptoms such as
    hopelessness, exhaustion, and thoughts of death. Depression can be
    treated but the first step is determining whether symptoms are
    present. This test could help.`,
    directions: `Over the last 2 weeks, how often have you been bothered by the following problems? There are 8 questions in total.`,
    url: '/tests/depression',
    questions: [
      'Little interest or pleasure in doing things.',
      'Feeling down, depressed or hopeless.',
      'Trouble falling asleep, staying asleep, or sleeping too much.',
      'Feeling tired or having little energy.',
      'Feeling bad about yourself - or that you’re a failure or have let yourself or your family down.',
      'Trouble concentrating on things, such as reading the newspaper or watching television.',
      'Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual.',
      'Thoughts that you would be better off dead or of hurting yourself in some way.',
    ],
    allOptions: [
      { value: 0, label: 'Not at all' },
      { value: 1, label: 'Several days' },
      { value: 2, label: 'More than half the days' },
      { value: 3, label: 'Nearly every day' },
    ],
  },
  {
    title: 'ADHD',
    subTitle: '',
    directions: ``,
    url: '/tests/adhd',
    questions: [
      'I feel overwhelmed by my emotions.',
      'I am able to handle the level of stress I experience.',
      'I have physical symptoms of anxiety, such as sweaty palms.',
      'I cannot get beyond long-past traumatic events or significant losses.',
    ],
    allOptions: [
      { value: 1, label: 'Not at all' },
      { value: 2, label: 'Several days' },
      { value: 3, label: 'More than half the days' },
      { value: 4, label: 'Nearly every day' },
    ],
  },
];

export const quizOptions = _.map(quizzes, ({ title, url }) => ({
  label: title,
  value: url,
}));

export const askTherapists = [
  {
    tag_id: 'feb24a0b-f855-49e2-8cea-e236880bb40b',
    pageTitle:
      'Why did you become a therapist, and what motivates you to continue?',
    seoTitle: 'Therapists Answer: Why I Became a Therapist',
    navTitle: 'Why did you become a therapist?',
    slug: 'why-i-became-a-therapist',
  },
  {
    tag_id: '1a619b7f-0ba9-4fe3-a021-ba4cee394d9a',
    pageTitle:
      'For people that are hesitant to try therapy, what do you wish you could tell them?',
    seoTitle: 'Therapists Answer: Advice for Starting Therapy',
    navTitle: 'Advice for starting therapy?',
    slug: 'advice-for-starting-therapy',
  },
  {
    tag_id: '94946732-30bf-4df4-8d7b-3fb77ce27371',
    pageTitle:
      'What should people thinking about therapy with you, know about you?',
    seoTitle: 'Therapists Answer: What Should Clients Know About Me',
    navTitle: 'What should clients know about you?',
    slug: 'what-are-therapists-like',
  },
  {
    tag_id: '8fd19cfc-0320-4556-ac2a-82870969919e',
    pageTitle:
      'What is therapy like? What does a typical session with you look like?',
    seoTitle: 'Therapists Answer: What is Therapy Like',
    navTitle: 'What is therapy like?',
    slug: 'what-is-therapy-like',
  },
  {
    tag_id: '096b55af-0434-4f31-be7e-07f13a1cc40e',
    pageTitle: 'How can teletherapy work? How can you provide care remotely?',
    seoTitle: 'Therapists Answer: How can Teletherapy work',
    navTitle: 'How can teletherapy work?',
    slug: 'how-can-teletherapy-work',
  },
];

function useCreateMaps(addUrl: Function) {
  const issueDocs = useSearchableIssues();
  const approachDocs = useSearchableApproaches();

  _.each(openStates, (state) => {
    addUrl(getStateUrl(state));
    addUrl(`${getStateUrl(state)}/online-therapy`);

    _.each(issueDocs, (issueDoc) => {
      addUrl(getStateUrl(state, issueDoc.label));
    });

    _.each(approachDocs, (approachDoc) => {
      addUrl(getStateUrl(state, approachDoc.label));
    });

    if (_.includes(insuranceStates, state)) {
      _.each(getStatePayments(state), (payment) => {
        addUrl(getStateUrl(state, payment));
      });
    }
  });
}

export function useStateSitemap() {
  const urls: string[] = [];
  const addUrl = (path: string) =>
    urls.push(`<url><loc>https://mintleaf.co${path}</loc></url>`);

  useCreateMaps(addUrl);

  console.log(urls.join('\n'));
}

export function useStateWhitelist() {
  const urls: string[] = [];
  const addUrl = (path: string) => urls.push(`'^${path}$',`);

  useCreateMaps(addUrl);

  console.log(urls.join('\n'));
}

export const emailRegex = /\S+@\S+\.\S+/;
