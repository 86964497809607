import styles from './State.module.scss';
import Paging from '../../containers/Paging/Paging';
import UserPage from '../../containers/UserPage/UserPage';
import {
  useAbsoluteUrl,
  valuesToOptions,
  Option,
  getStateUrl,
  getStatePayments,
  allGenders,
  insuranceStates,
  tagsToOptions,
  getTagFromSlug,
  getValueFromSlug,
  getSlug,
  shortenDC,
} from '../../helpers';
import Select from '../../components/Select/Select';
import _ from 'lodash';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { HeaderTags } from '../../components/HeaderTags/HeaderTags';
import { useEffect, useState } from 'react';
import { useDocListSelector } from '../../redux/selectors';
import { TagDoc } from '../../../src-server/models';

interface StateProps {
  state: string;
}

function State(props: StateProps) {
  const { state } = props;
  const { tag: slug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const allPayments = getStatePayments(state);
  const allPrices = [
    '$ — Less than $90',
    '$$ — $90 - 130',
    '$$$ — More than $130',
  ];
  const allTeletherapy = ['Online Therapy'];

  const issueDocs = useSearchableIssues();
  const approachDocs = useSearchableApproaches();

  const [issue, setIssue] = useState<Option>();
  const [approach, setApproach] = useState<Option>();
  const [gender, setGender] = useState<Option>();
  const [payment, setPayment] = useState<Option>();
  const [teletherapy, setTeletherapy] = useState<Option>();
  const [price, setPrice] = useState<Option>();

  const tagsLoaded = _.size(issueDocs) && _.size(approachDocs);

  const issueSlug = searchParams.get('issue') || slug;
  const approachSlug = searchParams.get('approach') || slug;
  const genderSlug = searchParams.get('gender') || slug;
  const paymentSlug = searchParams.get('payment') || slug;
  const teletherapySlug = searchParams.get('teletherapy') || slug;
  const priceSlug = searchParams.get('price') || slug;

  useEffect(() => {
    if (!tagsLoaded) {
      return;
    }

    const issueTag = getTagFromSlug(issueDocs, issueSlug);
    const approachTag = getTagFromSlug(approachDocs, approachSlug);
    const genderValue = getValueFromSlug(allGenders, genderSlug);
    const paymentValue = getValueFromSlug(allPayments, paymentSlug);
    const teletherapyValue = getValueFromSlug(allTeletherapy, teletherapySlug);
    const priceValue = getValueFromSlug(allPrices, priceSlug);

    const newIssue = tagsToOptions(issueTag) as Option;
    const newApproach = tagsToOptions(approachTag) as Option;
    const newGender = valuesToOptions(genderValue) as Option;
    const newPayment = valuesToOptions(paymentValue) as Option;
    const newTeletherapy = valuesToOptions(teletherapyValue) as Option;
    const newPrice = valuesToOptions(priceValue) as Option;

    setIssue(newIssue || null);
    setApproach(newApproach || null);
    setGender(newGender || null);
    setPayment(newPayment || null);
    setTeletherapy(newTeletherapy || null);
    setPrice(newPrice || null);
  }, [
    issueSlug,
    approachSlug,
    genderSlug,
    paymentSlug,
    teletherapySlug,
    priceSlug,
    tagsLoaded,
  ]);

  const userParams = {
    state: shortenDC(state),
    teletherapy: teletherapy ? true : null,
    issues: issue ? [issue.value] : null,
    gender: gender ? [gender.value] : null,
    payments: payment ? [payment.value] : null,
    approaches: approach ? [approach.value] : null,
    price: price?.value || null,
    search: true,
    tag_ids: _.compact([issue?.value, approach?.value]),
    pageSize: 7,
  };

  const stateLabel = shortenDC(state);
  const onlineLabel = teletherapy ? 'Online ' : '';
  const tagOption = issue || gender || payment || approach;
  const tagLabel = tagOption?.label ? `${tagOption?.label} ` : '';
  const trimmedTagLabel = _.replace(tagLabel, 'Therapy', '');
  const title = `${onlineLabel}${trimmedTagLabel}Therapists in ${stateLabel}`;

  const metaDescription =
    'Find the right therapist for you. Each profile lists the insurances the provider takes, typical costs per session, and how to get in contact.';

  const isInsuranceFilterable = _.includes(insuranceStates, state);
  const filterStyles = isInsuranceFilterable
    ? styles.filterListThree
    : styles.filterListFour;

  const paramHasState = (paramName: string) => {
    const mapping: any = {
      issue,
      approach,
      gender,
      payment,
      teletherapy,
      price,
    };

    return Boolean(mapping[paramName]);
  };

  const createOnChange = (paramName: string) => {
    return (option?: Option) => {
      const value = getSlug(option?.label);
      let removeFirstTag = false;

      if (value) {
        if (paramHasState(paramName) && !searchParams.has(paramName)) {
          removeFirstTag = true;
        }

        searchParams.set(paramName, value);
      } else {
        if (searchParams.has(paramName)) {
          searchParams.delete(paramName);
        } else {
          removeFirstTag = true;
        }
      }

      searchParams.delete('page');

      const firstTag =
        (removeFirstTag ? '' : slug) ||
        searchParams.get('issue') ||
        searchParams.get('approach') ||
        searchParams.get('gender') ||
        searchParams.get('payment') ||
        searchParams.get('teletherapy');

      searchParams.forEach((value, key) => {
        if (value === firstTag) {
          searchParams.delete(key);
        }
      });

      navigate({
        pathname: getStateUrl(state, firstTag || ''),
        search: `?${searchParams}`,
      });
    };
  };

  return (
    <div className={styles.searchPage}>
      <HeaderTags
        title={`${title} | MintLeaf`}
        description={metaDescription}
        url={useAbsoluteUrl()}
        state={state}
      />

      <div>
        <h1 className={styles.pageTitle}>{title}</h1>
        <div className={styles.pageSubtitle}>{metaDescription}</div>
      </div>

      <div className={filterStyles}>
        <Select
          options={tagsToOptions(issueDocs)}
          value={issue}
          onChange={createOnChange('issue')}
          placeholder="Specialties"
          isSearchable={false}
          isClearable
          isOutline
        />

        <Select
          options={tagsToOptions(approachDocs)}
          value={approach}
          onChange={createOnChange('approach')}
          placeholder="Types of Therapy"
          isSearchable={false}
          isClearable
          isOutline
        />

        {isInsuranceFilterable && (
          <Select
            options={valuesToOptions(
              _.without(getStatePayments(state), 'Sliding Scale'),
            )}
            value={payment}
            onChange={createOnChange('payment')}
            placeholder="Insurance"
            isSearchable={false}
            isClearable
            isOutline
          />
        )}

        <Select
          options={valuesToOptions(allTeletherapy)}
          value={teletherapy}
          onChange={createOnChange('teletherapy')}
          placeholder="Online Therapy"
          isSearchable={false}
          isClearable
          isOutline
        />

        <Select
          options={valuesToOptions(allPrices)}
          value={price}
          onChange={createOnChange('price')}
          placeholder="Price"
          isSearchable={false}
          isClearable
          isOutline
        />

        {/* <Select
          options={valuesToOptions(allGenders)}
          value={gender}
          onChange={createOnChange('gender')}
          placeholder="Gender"
          isSearchable={false}
          isClearable
          isOutline
        /> */}
      </div>

      <Paging
        component={UserPage}
        params={userParams}
        showAllPages={false}
        className={styles.searchResults}
      />
    </div>
  );
}

export function useSearchableIssues() {
  const typeDocs = useDocListSelector({
    collection: 'tag',
    filter: ({ category, label, is_introducing, is_deprecating }: TagDoc) =>
      category === 'Types' &&
      label !== 'Individual Therapy' &&
      !is_introducing &&
      !is_deprecating,
    order: ['position', 'asc'],
  });

  const issueDocs = useDocListSelector({
    collection: 'tag',
    filter: {
      category: 'Specialties',
      is_introducing: false,
      is_deprecating: false,
    },
    order: ['position', 'asc'],
  });

  return [...typeDocs, ...issueDocs];
}

export function useSearchableApproaches() {
  return useDocListSelector({
    collection: 'tag',
    filter: ({ category, position, is_deprecating, is_introducing }: TagDoc) =>
      category === 'Approaches' &&
      position &&
      !is_deprecating &&
      !is_introducing,
    order: ['position', 'asc'],
  });
}

export default State;
